import "./Homepage.css";
import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Button from "@mui/material/Button";



const baseURL = window.location.origin + '/signin'
const homePageURL = window.location.origin

const serverBaseURL = 1 ? 'https://account-authfast.workr.in' : 'http://localhost:3000'



function Homepage() {


  const [isUserLogin, setIsUserLogin] = useState(false)



  async function checkOtherTokens() {
    window.location.href = serverBaseURL + '/token/client?redict_url=' + (baseURL + '?noUser=true')
  }

  async function checkLoginAtHomepage() {
    let params = (new URL(document.location)).searchParams;
    let noUser = params.get("noUser")
    let loggedIn = params.get("loggedIn")

    if (noUser) {
      setIsUserLogin(false)
    }
    else if (loggedIn) {
      setIsUserLogin(true)
    }
    else {
      checkOtherTokens()
    }
  }



  async function refreshTheAccessToken() {


    let url = 'https://auth-service-client2.up.railway.app/refresh-token'

    try {

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          refresh_token: JSON.parse(window.localStorage.getItem('refresh_token'))
        }) // body data type must match "Content-Type" header
      });

      let reponseData = await response.json()
      let signinData = reponseData.data.data
      window.localStorage.setItem('userDetials', JSON.stringify(signinData))
      window.localStorage.setItem('access_token', JSON.stringify(signinData.access_token))

      window.localStorage.setItem('refresh_token', JSON.stringify(signinData.refresh_token))

      window.localStorage.setItem('access_token_expires_at', JSON.stringify(signinData.access_token_expires_at))

      window.localStorage.setItem('refresh_token_expires_at', JSON.stringify(signinData.access_token_expires_at))

      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);

    }
    catch (err) {
      console.log(err)
      alert(err)
    }

  }


  useEffect(() => {
    checkLoginAtHomepage()
  }, [])



  return (
    <div className="App">
      <Navbar></Navbar>

      <h1> OROTRON Home Page</h1>

      {isUserLogin ? <Button onClick={refreshTheAccessToken} > Refresh Access Token </Button> : ''}
    </div>
  );
}

export default Homepage;
