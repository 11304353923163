import "./SignIn.css";
import Signin from "./components/Signin";

function SignIn() {
  return (
    <div>
      <Signin> </Signin>
    </div>
  );
}

export default SignIn;
