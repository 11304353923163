import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  //   const [validationErrors, setValidationErrors] = useState([]);
  const [emailValidationErrors, setEmailValidationErrors] = useState("");
  const [passwordValidationErrors, setPasswordValidationErrors] = useState("");


  const baseURL = window.location.origin + '/signin'

  const serverBaseURL = 1 ? 'https://account-authfast.workr.in' : 'http://localhost:3000'

  const homePageURL = window.location.origin



  async function checkLogin() {

    let params = (new URL(document.location)).searchParams;

    let code = params.get("code");
    let noUser = params.get("noUser")
    let logout = params.get("logout")

    if (code) {

      let url = 'https://auth-service-client2.up.railway.app/auth-code'

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          code
        }) // body data type must match "Content-Type" header
      });

      let reponseData = await response.json()
      let signinData = reponseData.data.data
      window.localStorage.setItem('userDetials', JSON.stringify(signinData))
      window.localStorage.setItem('access_token', JSON.stringify(signinData.access_token))

      window.localStorage.setItem('refresh_token', JSON.stringify(signinData.refresh_token))

      window.localStorage.setItem('access_token_expires_at', JSON.stringify(signinData.access_token_expires_at))


      window.localStorage.setItem('refresh_token_expires_at', JSON.stringify(signinData.access_token_expires_at))


      window.location.href = homePageURL + '?loggedIn=true'
      window.history.pushState({ id: 'index', source: 'web' }, 'Index', homePageURL);
    }
    else if (noUser) {
      window.location.href = homePageURL + "?noUser=true"
    }
    else if (logout) {
      window.localStorage.removeItem('userDetials')
      window.location.href = homePageURL
    }
  }

  useEffect(() => {
    checkLogin()
  }, []);



  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            OROTRON Sign in
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Signin;
