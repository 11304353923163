import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { fabClasses } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
export default function Navbar() {

  const [isUserLogin, setIsUserLogin] = useState(false)


  const baseURL = window.location.origin + '/signin'
  const homePageURL = window.location.origin

  const serverBaseURL = 1 ? 'https://account-authfast.workr.in' : 'http://localhost:3000'


  const logout = () => {
    window.location.href = serverBaseURL + '/logout/client?redict_url=' + baseURL
  }

  const login = () => {
    window.location.href = serverBaseURL + '/signin/client?redict_url=' + baseURL
    window.localStorage.clear()
  }



  async function checkOtherTokens() {
    window.location.href = serverBaseURL + '/token/client?redict_url=' + (baseURL + '?noUser=true')
  }

  const loginUserDetials = () => {
    let user = window.localStorage.getItem('userDetials')
    if (user) {
      user = JSON.parse(user)
      return user.profile.email
    }
    return ""
  }

  async function checkLoginAtHomepage() {
    let params = (new URL(document.location)).searchParams;
    let noUser = params.get("noUser")
    let loggedIn = params.get("loggedIn")

    if (noUser) {

      setIsUserLogin(false)
      // window.history.pushState(null, "", window.location.href.split("?")[0]);
    }
    else if (loggedIn) {

      setIsUserLogin(true)

      // window.history.pushState(null, "", window.location.href.split("?")[0]);
    }
    else {
      checkOtherTokens()
    }
  }



  useEffect(() => {
    checkLoginAtHomepage()
  }, [])
  const loginButton = () => {
    return (
      <span>
        <Button color="inherit" onClick={login}>
          Login
        </Button>
      </span>
    );
  }

  const userDetails = () => {
    return (
      <span>
        <AccountCircleIcon />
        {loginUserDetials()}
      </span>
    );
  }

  const logoutButton = () => {
    return <Button color="inherit" onClick={logout}>
      Logout
    </Button>;
  }

  return (

    < Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            OROTRON Home  {isUserLogin}
          </Typography>

          <span>{isUserLogin ? userDetails() : ""}</span>



          {isUserLogin ? logoutButton() : loginButton()}


        </Toolbar>
      </AppBar>
    </Box >
  );
}
